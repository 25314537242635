const kontakt = {
  title: "Kontakt",
  company: "Markt der Möglichkeiten",
  firstname: "Jeanette",
  lastname: "von Mauch",
  address: {
    street: "Josephsplatz 1",
    plz: "80798",
    location: "München",
    country: "Deutschland",
  },
  email: "info@mdm-schwabing.de",
  phone: "",
  mobile: "",
  fax: "xxxx",
  googlemaps: "https://goo.gl/maps/2yf4prct6HqUJ8Ce6",
  facebook: "https://de-de.facebook.com/MarktDerMoeglichkeiten/",
  instagram: "https://www.instagram.com/markt_der_moeglichkeiten",
  date: {
    day_1:""
  }
  
};

export default kontakt;
