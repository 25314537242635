<template>
  <footer class="footer" :class="{ white: color }">
    <div class="footer__wrapper">
      <div class="footer__content">
        <div v-if="!nologo" class="footer__logo">
          <the-logo />
        </div>
        <div class="footer__box">
          <div class="footer__block footer__block--left">
            <div class="footer__hl">
              Veranstaltungsort
            </div>
            <div class="footer__item">
              {{ kontakt.company }}
            </div>
            <div class="footer__item">
              <a :href="kontakt.googlemaps" target="_blank" class="footer__a"
                >{{ kontakt.address.street }}
              </a>
            </div>
            <div class="footer__item">
              <a :href="kontakt.googlemaps" target="_blank" class="footer__a"
                >{{ kontakt.address.plz }} {{ kontakt.address.location }}
              </a>
            </div>
          </div>
          <div class="footer__block">
            <div class="footer__hl">
              Kontakt
            </div>
            <div class="footer__item">
              <a :href="getMail" class="footer__a"> {{ kontakt.email }}</a>
            </div>
            <div class="footer__item">
              <a :href="getPhone" class="footer__a">{{ kontakt.phone }}</a>
            </div>
          </div>
          <div class="footer__block">
            <div class="footer__hl">
              Networks
            </div>
            <div class="footer__item">
              <a :href="kontakt.instagram" class="footer__a" target="_blank">
                Instagram
              </a>
            </div>
            <div class="footer__item">
              <a :href="kontakt.facebook" class="footer__a" target="_blank">
                Facebook
              </a>
            </div>
          </div>
          <div class="footer__block footer__block--right">
            <div class="footer__hl">
             Wichtiges
            </div>
            <div class="footer__item">
              <router-link :to="prefixPath('datenschutz')" class="footer__a">
                Datenschutz
              </router-link>
            </div>
            <div class="footer__item">
              <router-link :to="prefixPath('impressum')" class="footer__a">
                Impressum
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import getPathFunction from "@/utilities/composition/getPathFunction";
import contactInformation from "@/utilities/siteinformation/contactInformation";
import { useStore } from "vuex";

import TheLogo from "../../ui/logo/TheLogo.vue";
import { onMounted } from "@vue/runtime-core";
export default {
  components: { TheLogo },
  props: { nologo: Boolean, color: String },
  setup() {
    const store = useStore();
    const kontakt = contactInformation;
    const publicPath = process.env.BASE_URL;
    const getMail = "mailto:" + kontakt.email;
    const getPhone = "tel:" + kontakt.phone;

    const { getPath } = getPathFunction();

    function prefixPath(el) {
      return publicPath + el;
    }

    function closeMenu() {
      store.commit("setMenuState", false);
    }

    onMounted(() => {
      let els = document.querySelectorAll(".footer__item");
      els.forEach((element) => {
        element.addEventListener("click", closeMenu);
      });
    });
    return { kontakt, getMail, getPhone, prefixPath, getPath };
  },
};
</script>

<style lang="scss">
.footer {
  padding-top: 0;
  padding-bottom: 60px;
  width: 100%;

  margin-bottom: 0 !important;
  color: white;
  &.white {
    color: black;
  }

  @media screen and (min-width: $medium) {
    padding-bottom: 0;
  }
}

.footer__box {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.footer__instablock {
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;

  padding: 20px 10px;

  @media screen and (min-width: $medium) {
    flex: 1 0 0 100%;
  }
}
.footer__leftblock {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;

  padding: 20px 10px;

  @media screen and (min-width: $medium) {
    flex: 0 0 auto;
  }
}

.footer__middleblock {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;

  padding: 20px 10px;

  @media screen and (min-width: $medium) {
    flex: 1 0 33.33%;
  }
}
.footer__rightblock {
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;

  padding: 20px 10px;

  @media screen and (min-width: $medium) {
    flex: 1 0 33.33%;
  }
}

.footer__block {
  margin-top: 0;
  margin-bottom: 63px;
  text-align: center;

  flex: 1 0 100%;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: $medium) {
    margin-top: 0;
    flex: 0 0 auto;

    &--left {
      text-align: left;
    }
    &--right {
      text-align: right;
    }
  }
}

.footer__hl {
  text-align: inherit;

  font-size: 20px;
  line-height: 1.3;
  letter-spacing: 0.25px;
  color: inherit;
  opacity: 1;

  font-family: $font-family-herohl;

  @media screen and (min-width: $medium) {
    font-size: 25px;
    line-height: 1.3;
  }
}
.footer__item {
  text-align: inherit;

  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0;
  color: inherit;
  opacity: 1;

  @media screen and (min-width: $medium) {
    font-size: 18px;
    line-height: 23px;
  }
}
.footer__a {
  display: block;
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  white-space: nowrap;
  transition: 0.2s;

  :not(.is-mobile) &:hover {
    outline: none;
    color: $main-color-1;
  }
}

.footer__logo {
  width: 160px;
  height: 160px;
  margin: 0 auto;
  margin-bottom: 100px;

  @media screen and (min-width: $medium) {
    width: 201px;
    height: 160px;
    margin: 0 auto;
    margin-bottom: 120px;
  }
}

.footer__instagram {
  margin-top: 10px;
}
</style>
