<template>
  <div class="thelogo">

<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	viewBox="0 0 141.7 141.7" style="enable-background:new 0 0 141.7 141.7;" xml:space="preserve">

<g>
	<path class="thelogo__color" d="M119.6,22.2C106.6,9.1,89.3,2,70.9,2C52.5,2,35.2,9.1,22.2,22.2C9.1,35.2,2,52.5,2,70.9
		c0,18.4,7.2,35.7,20.2,48.7c13,13,30.3,20.2,48.7,20.2c18.4,0,35.7-7.2,48.7-20.2c13-13,20.2-30.3,20.2-48.7
		C139.8,52.5,132.6,35.2,119.6,22.2z M70.9,138.8c-37.5,0-68-30.5-68-68c0-37.5,30.5-68,68-68c37.5,0,68,30.5,68,68
		C138.8,108.4,108.3,138.8,70.9,138.8z"/>
	<path class="thelogo__color" d="M121,20.8C107.6,7.4,89.8,0,70.9,0C51.9,0,34.1,7.4,20.8,20.8C7.4,34.1,0,51.9,0,70.9
		c0,18.9,7.4,36.7,20.8,50.1c13.4,13.4,31.2,20.8,50.1,20.8c18.9,0,36.7-7.4,50.1-20.8c13.4-13.4,20.8-31.2,20.8-50.1
		C141.7,51.9,134.4,34.1,121,20.8z M70.9,140.8c-38.6,0-70-31.4-70-70c0-38.6,31.4-70,70-70c38.6,0,70,31.4,70,70
		C140.8,109.4,109.4,140.8,70.9,140.8z"/>
	<path class="thelogo__color" d="M15.5,83c-0.4-0.1-0.8-0.2-1.3-0.3c-0.4-0.1-0.8-0.2-1.3-0.3c-0.4-0.1-0.8-0.2-1.3-0.3
		c-0.4-0.1-0.8-0.2-1.3-0.3l4.4-2.9L14.6,78l-7.1,1.2l0.2,1l4.2-0.7l0.5-0.1l-0.5,0.3L9,81.6l0.2,1l3.3,0.7c0.1,0,0.2,0,0.4,0.1
		c0.1,0,0.2,0,0.4,0.1l-0.7,0.1l-4.2,0.7l0.2,1l7.1-1.2L15.5,83z"/>
	<path class="thelogo__color" d="M17.3,89.3l-7.7-0.4l0.4,1L12,90l1,2.8l-1.6,1.3l0.4,1.1l5.9-4.9L17.3,89.3z M15.4,90.7
		c-0.2,0.2-0.4,0.3-0.6,0.5c-0.2,0.2-0.4,0.3-0.6,0.5c-0.2,0.2-0.4,0.3-0.6,0.5L12.9,90l3,0.2C15.8,90.4,15.6,90.5,15.4,90.7z"/>
	<path class="thelogo__color" d="M17.5,99.9c0,0,0-0.1,0-0.1c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0,0.3,0.1,0.4,0.1
		c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.3-0.2,0.6-0.4,0.8-0.7c0.1-0.1,0.2-0.3,0.2-0.4
		c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2,0-0.3-0.1-0.6c-0.1-0.2-0.2-0.4-0.3-0.7l-1-2l-6.4,3.3l0.5,1l2.7-1.4l0.4,0.7
		c-0.1,0.2-0.1,0.4-0.2,0.7c-0.1,0.2-0.2,0.4-0.3,0.6l-1.2,1.7l0.6,1.1l1.4-2.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.2
		c0.1-0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2C17.5,99.9,17.5,99.9,17.5,99.9z M17.2,97.7l2.1-1.1l0.4,0.7
		c0.2,0.4,0.3,0.6,0.2,0.8c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.2-0.7,0.2c-0.2,0-0.4,0-0.6-0.2
		c-0.2-0.1-0.3-0.3-0.5-0.6L17.2,97.7z"/>
	<polygon class="thelogo__color" points="25.5,105 21.3,104.6 23.9,102.8 23.3,101.9 17.4,106 18.1,106.9 20.9,104.9 19.8,109.4 20.7,110.6 
		21.8,105.6 26.3,106.1 	"/>
	<polygon class="thelogo__color" points="27.1,108.6 28.7,110.3 24.2,114.6 24.9,115.5 29.5,111.2 31.1,112.9 31.8,112.2 27.8,108 	"/>
	<path class="thelogo__color" d="M40.7,118.9l-1.6,2.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1-0.2-0.3-0.3-0.5-0.4
		c-0.6-0.4-1.2-0.5-1.8-0.2c-0.6,0.2-1.1,0.7-1.5,1.3c-0.3,0.4-0.5,0.9-0.6,1.3c-0.1,0.5-0.1,0.9,0.1,1.2c0.2,0.4,0.4,0.7,0.7,0.9
		c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2-0.1
		l-0.3,0.5l0.9,0.6l4.1-6.1L40.7,118.9z M38,122.9c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.4,0.2
		c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0-0.5-0.1-0.7-0.2c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2,0-0.4,0.1-0.6
		c0.1-0.2,0.2-0.5,0.4-0.8c0.2-0.3,0.3-0.5,0.5-0.6c0.2-0.2,0.4-0.3,0.5-0.3c0.4-0.1,0.7-0.1,1,0.1c0.3,0.2,0.5,0.5,0.5,0.9
		C38.5,122,38.4,122.5,38,122.9z"/>
	<path class="thelogo__color" d="M46,124.5c-0.1-0.1-0.2-0.3-0.4-0.4c-0.1-0.1-0.3-0.2-0.5-0.3c-0.6-0.3-1.3-0.3-1.9-0.1
		c-0.6,0.3-1.1,0.8-1.5,1.5c-0.3,0.7-0.4,1.4-0.3,2c0.2,0.7,0.6,1.2,1.3,1.5c0.3,0.2,0.7,0.3,1,0.3c0.3,0,0.6,0,0.9-0.2
		c0.3-0.1,0.5-0.3,0.6-0.5l0.1-0.1l-0.9-0.5l-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0
		c-0.1,0-0.3-0.1-0.4-0.1c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.2-0.2-0.3-0.3-0.5c0-0.2-0.1-0.4,0-0.6c0-0.2,0.1-0.4,0.2-0.6l3.2,1.6
		l0.2-0.3c0.3-0.6,0.4-1.1,0.4-1.5c0-0.2,0-0.4-0.1-0.6C46.2,124.8,46.1,124.6,46,124.5z M45.2,126.2l-2.2-1.1
		c0.1-0.1,0.2-0.3,0.4-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.3-0.1,0.6-0.1,0.8,0c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.2,0.2
		c0.1,0.2,0.2,0.4,0.2,0.6C45.4,125.8,45.3,126,45.2,126.2z"/>
	<path class="thelogo__color" d="M51.6,126.6c-0.2,0-0.3,0-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.2l0.2-0.6l-1-0.4l-1.8,4.9l1,0.4l0.9-2.4
		c0.2-0.6,0.5-1,0.8-1.1l0,0c0.3-0.1,0.6-0.2,0.9-0.1c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3l0.4-1
		c-0.2-0.1-0.3-0.2-0.5-0.3C52,126.7,51.8,126.6,51.6,126.6z"/>
	<path class="thelogo__color" d="M64.9,128.8c-0.2,0.4-0.4,0.7-0.6,1.1c-0.2,0.4-0.4,0.7-0.6,1.1c-0.2,0.4-0.4,0.8-0.6,1.1l-1.4-5l-1.1-0.1
		l-1,7.1l1,0.1l0.6-4.2l0.1-0.6l0.1,0.6l0.9,3.3l1,0.1l1.7-3c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3l-0.1,0.7l-0.6,4.2
		l1,0.1l1-7.1l-1-0.1C65.3,128.1,65.1,128.5,64.9,128.8z"/>
	
		<rect x="70.9" y="126.4" transform="matrix(0.9997 -2.418776e-02 2.418776e-02 0.9997 -3.0482 1.7651)" class="thelogo__color" width="1.1" height="1.1"/>
	<path class="thelogo__color" d="M72.5,127.7c-0.9,0-1.6,0.4-2.2,1c-0.5,0.7-0.8,1.6-0.8,2.8c0,1.2,0.3,2.1,0.9,2.8c0.6,0.6,1.3,1,2.2,0.9
		c0.9,0,1.6-0.4,2.2-1c0.6-0.7,0.8-1.6,0.8-2.8c0-1.2-0.3-2.1-0.9-2.8C74.1,128,73.4,127.7,72.5,127.7z M74,133.6
		c-0.3,0.5-0.8,0.8-1.4,0.8c-0.6,0-1.1-0.2-1.4-0.7c-0.4-0.5-0.5-1.2-0.6-2.1c0-0.9,0.1-1.6,0.5-2.1c0.3-0.5,0.8-0.8,1.4-0.8
		c0.6,0,1.1,0.2,1.4,0.7c0.4,0.5,0.5,1.2,0.6,2.1C74.5,132.3,74.3,133.1,74,133.6z"/>
	
		<rect x="72.9" y="126.3" transform="matrix(0.9997 -2.400972e-02 2.400972e-02 0.9997 -3.0242 1.7986)" class="thelogo__color" width="1.1" height="1.1"/>
	<path class="thelogo__color" d="M81.6,130.5l0.2,0.9l1.5-0.3l0.3,1.8c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.4,0.1-0.6,0.2c-0.3,0.1-0.6,0-0.9,0
		c-0.3-0.1-0.5-0.2-0.7-0.4c-0.2-0.2-0.4-0.5-0.5-0.8c-0.2-0.3-0.3-0.7-0.3-1.2c-0.2-0.9-0.1-1.6,0.1-2.2c0.2-0.5,0.7-0.9,1.3-1
		c0.2,0,0.5,0,0.7,0c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.2,0.2,0.4l0,0.1l1-0.2l0-0.1c-0.1-0.3-0.2-0.6-0.5-0.8
		c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.3-0.1-0.5-0.2c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4,0-0.6,0.1c-0.3,0.1-0.6,0.1-0.8,0.3
		c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.4,0.4-0.5,0.6c-0.1,0.2-0.2,0.5-0.3,0.8c-0.2,0.6-0.2,1.3,0,2.2c0.1,0.8,0.4,1.5,0.7,1.9
		c0.2,0.2,0.4,0.4,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.4c0.2,0.1,0.5,0.2,0.8,0.2c0.3,0,0.6,0,1-0.1c0.7-0.1,1.4-0.4,2.1-0.9l-0.6-3.2
		L81.6,130.5z"/>
	<polygon class="thelogo__color" points="89.5,131.4 87.6,125.5 86.5,125.9 88.7,132.7 93.3,131.2 92.9,130.3 	"/>
	
		<rect x="94.9" y="122.8" transform="matrix(0.9139 -0.4059 0.4059 0.9139 -43.0774 49.6367)" class="thelogo__color" width="1.1" height="7.2"/>
	<path class="thelogo__color" d="M104.7,123.8l-0.1-0.1l-0.9,0.5l0.1,0.1c0.1,0.2,0.1,0.4,0.1,0.6c-0.1,0.2-0.3,0.5-0.7,0.7
		c-0.6,0.3-1.1,0.4-1.7,0.1c-0.6-0.3-1.1-0.8-1.5-1.6c-0.4-0.7-0.6-1.4-0.6-2.1c0-0.6,0.3-1.1,0.9-1.4c0.1-0.1,0.2-0.1,0.3-0.2
		c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2l0.1,0.1l0.9-0.5
		l-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.2-0.4-0.3c-0.3-0.2-0.7-0.2-1-0.2c-0.4,0-0.7,0.2-1.1,0.4
		c-0.4,0.2-0.7,0.5-1,0.8c-0.2,0.3-0.4,0.7-0.5,1.1c-0.1,0.4-0.1,0.9,0,1.4c0.1,0.5,0.3,1,0.6,1.5c0.6,1.1,1.4,1.8,2.2,2
		c0.8,0.3,1.6,0.2,2.4-0.3c0.2-0.1,0.4-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.4c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.3,0.1-0.5
		c0-0.2,0-0.4,0-0.5C104.8,124.1,104.8,123.9,104.7,123.8z"/>
	<polygon class="thelogo__color" points="107.4,114.8 109.4,117.2 107,119.1 105.1,116.6 104.2,117.3 108.7,122.9 109.6,122.3 107.6,119.8 
		109.9,117.9 111.9,120.4 112.8,119.7 108.3,114.1 	"/>
	<polygon class="thelogo__color" points="114.2,108.2 113.3,109.1 113.7,113.3 111.4,111.1 110.6,111.9 115.9,116.9 116.6,116.1 114.1,113.7 
		118.7,113.8 119.7,112.8 114.6,112.6 	"/>
	<polygon class="thelogo__color" points="121.7,108.6 119.9,107.2 121.4,105.1 120.6,104.5 119.1,106.7 117.5,105.5 119.3,103.1 118.5,102.5 
		116.1,105.9 121.9,110 124.4,106.5 123.6,105.9 	"/>
	
		<rect x="123" y="97.4" transform="matrix(0.4942 -0.8693 0.8693 0.4942 -25.3323 158.4848)" class="thelogo__color" width="1.1" height="7.2"/>
	<polygon class="thelogo__color" points="125.1,91.5 124.3,91.1 122,96.5 122.8,96.8 123.8,94.6 129.5,97.1 130,96.1 124.2,93.6 	"/>
	<polygon class="thelogo__color" points="131.4,89.2 129.2,88.6 129.9,86 129,85.8 128.3,88.3 126.4,87.8 127.2,84.9 126.2,84.6 125.1,88.6 
		132,90.5 133.2,86.3 132.3,86.1 	"/>
	<polygon class="thelogo__color" points="134.6,78.2 134.8,77.1 127.7,76.2 127.5,77.2 132.6,77.9 132.5,77.9 127.1,80.5 126.9,81.5 134,82.5 
		134.2,81.4 129.1,80.8 129.2,80.7 	"/>
	<path class="thelogo__color" d="M10.5,66.5c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C9,65.8,9.7,66.5,10.5,66.5z"
		/>
	<path class="thelogo__color" d="M130.2,64.6c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4
		C130.8,63.1,130.2,63.8,130.2,64.6z"/>
	<polygon class="thelogo__color" points="17.5,52 14.3,50.8 18.6,49.2 19.1,47.8 14.4,49.7 12.2,45.7 11.8,47 13.8,50.6 10.8,49.5 10.4,50.5 
		17.1,53.1 	"/>
	<path class="thelogo__color" d="M20.3,43.6c0.4,0,0.8-0.2,1.1-0.5c0.3-0.3,0.7-0.6,0.9-1.1c0.3-0.5,0.4-0.9,0.4-1.3c0-0.4-0.1-0.8-0.3-1.1
		c-0.2-0.3-0.7-0.7-1.4-1.1l-3.9-2.3l-0.5,0.9l3.7,2.2c0.7,0.4,1.1,0.7,1.2,0.9c0.1,0.2,0.2,0.4,0.2,0.7c0,0.3-0.1,0.5-0.3,0.8
		c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.5-0.1
		c-0.2-0.1-0.4-0.2-0.7-0.4l-4-2.3l-0.6,1l4.1,2.4C19.4,43.4,19.9,43.6,20.3,43.6z"/>
	<polygon class="thelogo__color" points="26.8,35.2 22.8,31.9 23,31.9 28.9,32.7 29.6,31.9 24.1,27.3 23.4,28.1 27.3,31.4 27.2,31.4 21.3,30.6 
		20.6,31.4 26.1,36 	"/>
	<path class="thelogo__color" d="M30.2,25c0.3,0.1,0.5,0.2,0.9,0.2c0.3,0,0.7-0.1,1.3-0.2l0.4-0.1c0.2-0.1,0.4-0.1,0.6-0.2
		c0.2,0,0.4-0.1,0.5-0.1c0.1,0,0.2,0,0.4,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.3,0.2,0.4c0,0.1,0,0.3,0,0.5l0,0
		c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.3,0.1-0.4,0.2c-0.3,0-0.5-0.1-0.7-0.3
		l-0.1-0.1l-0.9,0.7l0.1,0.1c0.2,0.3,0.5,0.5,0.8,0.6c0.3,0.1,0.7,0.1,1.1,0c0.4-0.1,0.7-0.4,1.2-0.7c0.4-0.4,0.7-0.7,0.9-1.1
		c0.2-0.4,0.2-0.7,0.1-1.1c-0.1-0.4-0.2-0.7-0.5-1c-0.1-0.2-0.3-0.3-0.4-0.4c-0.2-0.1-0.3-0.2-0.5-0.2c-0.2-0.1-0.4-0.1-0.7,0
		c-0.3,0-0.6,0.1-1,0.2l-0.4,0.1c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2,0-0.3,0.1-0.4,0.1c-0.2,0-0.4,0-0.5-0.1c-0.2-0.1-0.3-0.2-0.4-0.3
		c-0.2-0.2-0.2-0.4-0.2-0.7c0-0.3,0.2-0.5,0.5-0.8c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.2,0.1,0.3,0.1l0.1,0.1
		l0.9-0.7l-0.1-0.1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0-0.7,0.2c-0.2,0.1-0.5,0.3-0.8,0.5c-0.6,0.5-1,1-1,1.6
		c-0.1,0.5,0.1,1,0.4,1.5C29.8,24.7,30,24.9,30.2,25z"/>
	<polygon class="thelogo__color" points="38.7,16.8 41.8,22.2 42.8,21.6 39.6,16.2 41.6,15.1 41.2,14.3 36.2,17.2 36.7,17.9 	"/>
	<polygon class="thelogo__color" points="49.6,18.3 48.5,15.3 51.3,14.3 52.4,17.3 53.4,16.9 50.9,10.2 49.9,10.6 51,13.5 48.2,14.5 47.1,11.6 
		46.1,12 48.6,18.7 	"/>
	<path class="thelogo__color" d="M58.9,13.5l2.8-0.5l1,1.8l1.2-0.2L60.1,8l-1,0.2l-1.6,7.6l1-0.2L58.9,13.5z M59.7,9.6c0.1,0.2,0.3,0.5,0.4,0.7
		c0.1,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.2,0.4,0.4,0.6c0.1,0.2,0.3,0.4,0.4,0.7l-2.2,0.4L59.7,9.6z"/>
	<polygon class="thelogo__color" points="69.2,14.2 69.2,9.1 69.3,9.2 72.4,14.2 73.5,14.2 73.5,7.1 72.4,7.1 72.4,12.2 72.4,12.1 69.2,7.1 
		68.1,7.1 68.1,14.2 	"/>
	<path class="thelogo__color" d="M81.5,15.1c0.3,0,0.6-0.1,0.9-0.2c0.5-0.2,0.9-0.6,1.2-1.1c0.3-0.5,0.5-1.1,0.7-1.8c0.1-0.8,0.1-1.4,0-2
		c-0.1-0.6-0.4-1-0.8-1.4c-0.4-0.3-1-0.6-2-0.8l-2-0.4l-1.3,7.1l2.1,0.4C80.7,15.1,81.1,15.1,81.5,15.1z M80.4,8.7l0.9,0.2
		c0.3,0.1,0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.2,0.5,0.3c0.3,0.2,0.4,0.6,0.5,1c0.1,0.4,0.1,1,0,1.5c0,0.3-0.1,0.5-0.2,0.8
		c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.2,0.3-0.4,0.4C82.1,13.9,82,14,81.8,14c-0.3,0.1-0.8,0.1-1.4,0l-0.9-0.2L80.4,8.7z"/>
	<path class="thelogo__color" d="M93.3,13.3c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2l-0.1,0.4l-0.6,5.2l1,0.4l4.1-6.1L97,12.5l-2.9,4.2
		L93.9,17l0.1-0.3l0.6-5.1l-1-0.4l-3,4.2l-0.2,0.3l0.1-0.4l0.8-5l-1-0.4l-1.1,7.3l1,0.4L93.3,13.3z"/>
	<polygon class="thelogo__color" points="103.4,23.1 100.7,21.4 101.9,19.5 104.1,20.8 104.6,20 102.4,18.7 103.4,17 105.9,18.6 106.4,17.7 
		103,15.6 99.2,21.7 102.9,24 	"/>
	<path class="thelogo__color" d="M109.4,25.1l0.6,0.5c0,0.2,0,0.5,0,0.7c0,0.2,0,0.5-0.1,0.7l-0.5,2.1l1,0.8l0.5-2.5c0-0.1,0-0.2,0-0.3
		c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0.2,0,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.4,0
		c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.3,0.4-0.6,0.5-0.9
		c0-0.2,0.1-0.3,0-0.5c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.1-0.3-0.3-0.5c-0.1-0.2-0.3-0.3-0.5-0.5l-1.7-1.5l-4.7,5.5l0.8,0.7
		L109.4,25.1z M111.5,22.6l0.6,0.5c0.3,0.3,0.5,0.5,0.5,0.6c0.1,0.2,0.1,0.4,0,0.5c0,0.2-0.1,0.3-0.3,0.5c-0.1,0.2-0.3,0.3-0.5,0.4
		c-0.2,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.4-0.2-0.7-0.4l-0.6-0.5L111.5,22.6z"/>
	<polygon class="thelogo__color" points="114,33.7 116.6,31.4 116,36 116.9,37.1 117.6,32.1 122.1,32.1 121.2,31.1 117.1,31.1 119.5,29.1 
		118.8,28.2 113.3,32.8 	"/>
	<polygon class="thelogo__color" points="121.4,44.2 122.2,43.7 120.6,40.9 122.6,39.7 123.9,42 124.7,41.5 123.4,39.3 125.1,38.3 126.6,40.8 
		127.4,40.3 125.3,36.8 119.2,40.5 	"/>
	<path class="thelogo__color" d="M123.8,49.3l2.8-1.1l0.3,0.7c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.3,0.4-0.4,0.6l-1.4,1.6l0.5,1.2l1.6-1.9
		c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
		c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0
		c0.1,0,0.3-0.1,0.4-0.1c0.3-0.1,0.6-0.3,0.8-0.6c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.1-0.3,0.1-0.5c0-0.2,0-0.3,0-0.6
		c0-0.2-0.1-0.4-0.2-0.7l-0.8-2.1l-6.7,2.7L123.8,49.3z M129.6,47l0.3,0.8c0.1,0.4,0.2,0.6,0.2,0.8c0,0.2-0.1,0.3-0.2,0.5
		c-0.1,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.4,0.1-0.7,0.1c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.3-0.3-0.4-0.7l-0.3-0.7L129.6,47z"/>
	<path class="thelogo__color" d="M59,48.4c-5.9-1.4-7.5-3-10.3-4.9c-0.1,2.8-0.6,4.4-1.8,6.1c-0.6,0.9-1.2,1.7-1.9,2.6c-3,4.1-4.7,8.2-3.4,12.8
		c0.6,2.2,2,7.1,4.1,10.9c2.4,4.3,4.8,8.5,6.3,12.7c0.1-1.3,0.6-2.4,1.6-2.9c0.5-0.3,1-0.4,1.4-0.4c0.6,0,1,0.2,1.3,0.4
		c0.8,0.6,1.1,1.6,1,2.3c-0.1,0.7-0.5,1.2-1.1,1.5c-0.7,0.3-1.4-0.1-1.7-0.7c0,0,0,0,0,0c-0.1,0.7-0.1,1.8,0.4,3
		c0.4,1.2,1.3,2.6,3.1,2.4c1.8-0.3,2.1-1.5,2.1-3.8c0-2.1-1.4-4.5-2.9-6.9c-0.4-0.7-0.9-1.4-1.3-2.1c-0.4-0.7-0.9-1.5-1.4-2.2
		c-0.8-1.3-1.6-2.5-1.9-3.4c-0.8-2.9,1.4-5.8,3-7.8c0.2-0.3,0.4-0.6,0.6-0.8l0,0c0.3-0.4,0.6-0.8,0.8-1.2c0.9-1.3,0.2-5-0.6-7.3
		c-0.2-0.7,0.1-1.4,0.8-1.6c0.7-0.2,1.4,0.1,1.6,0.8c0.1,0.4,0.2,0.8,0.3,1.3c0.2,0.8,0.4,1.8,0.8,2.5c0.9,1.4,2.7,2.2,3.7,2.4
		c2,0.3,3.6,1.1,4.9,2.2c0-0.6,0-1.4,0-2.2c-0.1-2.9-0.5-6.6-1.3-8.3C66.6,53.6,62.2,49.1,59,48.4z"/>
	<path class="thelogo__color" d="M67,77.5c-0.1,0-0.2,0.2-0.2,0.4c0,0.5,0.4,1,0.9,1.3C67.7,77.9,67.2,77.5,67,77.5z"/>
	<path class="thelogo__color" d="M66.7,82.2c0,0-0.1-0.1-0.2-0.1c-0.6,0-1.1,1-1.1,2c0,0.9,0.3,1.5,0.7,1.5c0.7,0,1.2-1.2,1.5-3.1
		c-0.1,0-0.2,0.1-0.3,0.1C67.1,82.6,66.9,82.5,66.7,82.2z"/>
	<path class="thelogo__color" d="M72.6,66.3c1.3-1.1,3-1.9,5-2.2c0.9-0.2,2.7-1.1,3.6-2.4c0.5-0.7,0.7-1.6,0.8-2.5c0.1-0.5,0.2-0.9,0.3-1.3
		c0.2-0.7,1-1,1.6-0.8c0.7,0.2,1,1,0.8,1.6c-0.8,2.3-1.5,6.1-0.6,7.3c0.3,0.4,0.5,0.8,0.8,1.2c0.2,0.3,0.4,0.6,0.7,0.9
		c1.6,2.1,3.8,4.9,3,7.8c-0.2,0.9-1,2.1-1.9,3.4c-0.5,0.7-1,1.5-1.4,2.2c-0.4,0.7-0.8,1.4-1.3,2.1c-1.5,2.4-2.9,4.8-2.9,6.9
		c0,2.3,0.3,3.5,2.1,3.8c0.1,0,0.2,0,0.3,0c1.6,0,2.4-1.5,2.8-2.4c0.4-1.2,0.5-2.3,0.4-3c0,0,0,0,0,0c-0.3,0.6-1,1-1.7,0.7
		c-0.6-0.2-1-0.8-1-1.5c-0.1-0.7,0.2-1.8,1-2.3c0.3-0.2,0.7-0.4,1.3-0.4c0.5,0,0.9,0.1,1.4,0.4c1,0.5,1.4,1.6,1.6,3
		c1.5-4.2,3.9-8.5,6.3-12.8c2.2-3.8,3.5-8.7,4.1-10.9c1.3-4.7-0.3-8.7-3.4-12.8c-0.7-0.9-1.3-1.8-1.9-2.6c-1.2-1.7-1.6-3.3-1.8-6.1
		c-2.8,1.8-4.4,3.5-10.3,4.8c-3.1,0.7-7.6,5.3-8.6,7.4c-0.8,1.8-1.2,5.5-1.3,8.3C72.6,65,72.6,65.7,72.6,66.3z"/>
	<path class="thelogo__color" d="M123.6,70.9C123.6,41.6,100,18,70.7,18c-29.2,0-52.9,23.7-52.9,52.9c0,29.2,23.7,52.9,52.9,52.9
		C100,123.8,123.6,100.1,123.6,70.9z M69.2,80.9c-0.1,1.5-0.3,4.2-1.6,5.5c-0.4,0.4-0.9,0.6-1.5,0.6c-1.2,0-2.1-1.2-2.1-2.8
		c0-1.6,1-3.4,2.5-3.4c0.5,0,1,0.2,1.2,0.5c0-0.2,0-0.4,0-0.6c-1.4-0.5-2.4-1.6-2.4-2.7c0-0.9,0.6-1.8,1.6-1.8
		c0.4,0,0.8,0.2,1.2,0.5c0.8,0.8,0.9,2.2,1,2.9c0,0,0,0,0.1,0c0.1,0,0.2,0,0.2,0c0.1-0.1,0.5-0.3,0.5-1.3c0-0.7,0-2.1,0-2.1l0-0.1
		c0,0,0,0,0-0.1c0,0,0,0,0,0l0-0.1c0-0.3,0-0.8,0-1.5c0-0.5-0.1-1-0.1-1.4c-0.3-0.9-0.7-1.7-0.9-2.3l-0.1-0.2
		c-0.4-0.9-2.5-3.1-3.9-3.4c-2.5-0.6-3.5-1.3-4.5-2c0,0,0,0-0.1,0c-0.1,1-0.4,1.7-0.9,2.5c-0.3,0.4-0.6,0.8-0.9,1.3
		c-1.6,2.2-2.1,4-1.6,5.9c0.3,1,0.9,3.4,2,5.2c2.2,3.8,4,7.2,3.9,10.6c0,0.9,0,2.2-0.5,3.4c-0.7,1.7-2,2.7-3.9,2.9
		c-0.3,0-0.5,0-0.7,0c-1.7,0-3.2-0.8-4.2-2.3c0.1,0.9,0.2,1.8,0.2,2.7c0,1.7,0,4.3-1,6.7c-1.2,3.1-3.7,5-7.3,5.5c0,0,0,0-0.1,0
		c-0.4,0-0.8,0.1-1.2,0.1c-4.7,0-8.4-3.2-10.1-8.8C32.6,96,33,90,36.1,88.4c2.4-1.2,4-0.5,4.8,0c1.4,1,1.9,2.7,1.8,4
		c-0.1,1.1-0.7,2-1.6,2.4c-0.9,0.4-2-0.1-2.4-1c-0.3-0.7-0.1-1.4,0.4-1.9c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.4-0.1-1,0.2
		c-0.7,0.5-1.5,4.5,0,8.8c0.8,2.3,2.8,6,7.2,5.5c4.7-0.7,5.1-4.6,5.1-8.6c0-5.9-5-12.4-8.7-18.9c-2.4-4.2-5.8-8.6-6.5-11.3
		c-1.7-6.4,4-12.7,7.3-17c0.6-0.9,1.3-1.7,1.8-2.5c2-3,0.4-11.2-1.2-15.7c-0.3-0.9,0.2-2,1.1-2.3c0.9-0.3,2,0.2,2.3,1.1
		c0.8,2.2,1,5.7,2.5,8c2,3,5.9,4.9,8,5.3c8.3,1.4,12.1,6.1,14.1,10.1c2-4,5.8-8.7,14.1-10c2.1-0.5,5.9-2.3,8-5.3
		c1.6-2.3,1.8-5.8,2.5-8c0.3-0.9,1.4-1.5,2.3-1.1c0.9,0.3,1.5,1.4,1.1,2.3c-1.5,4.5-3.2,12.7-1.2,15.7c0.5,0.8,1.2,1.6,1.8,2.5
		c3.2,4.4,9,10.7,7.2,17c-0.7,2.7-4.2,7.1-6.6,11.3c-3.7,6.5-8.7,12.9-8.7,18.9c0,4,0.4,7.9,5.1,8.6c4.4,0.5,6.4-3.2,7.2-5.5
		c1.5-4.3,0.8-8.3,0.1-8.8c-0.6-0.3-0.9-0.3-1-0.2c-0.1,0.1-0.2,0.3-0.2,0.4c0.5,0.5,0.6,1.2,0.4,1.9c-0.4,0.9-1.4,1.4-2.4,1
		c-0.9-0.4-1.5-1.2-1.6-2.4c-0.1-1.3,0.4-3,1.8-4c0.8-0.5,2.4-1.2,4.8,0c3.1,1.6,3.4,7.6,2,12.4c-1.7,5.6-5.4,8.8-10.1,8.8
		c-0.4,0-0.8,0-1.2-0.1c0,0,0,0-0.1,0c-3.5-0.5-6-2.4-7.3-5.5c-1-2.4-1-5-1-6.7c0-0.9,0.1-1.8,0.2-2.7c-1,1.5-2.5,2.3-4.2,2.3h0h0
		c-0.2,0-0.4,0-0.7,0c-1.9-0.3-3.2-1.3-3.9-3c-0.5-1.3-0.5-2.6-0.5-3.4c0-3.4,1.8-6.8,4-10.6c1-1.8,1.7-4.2,2-5.2
		c0.5-1.9,0.1-3.7-1.6-5.9c-0.3-0.4-0.6-0.9-0.9-1.3c-0.5-0.7-0.8-1.5-0.9-2.5c0,0,0,0-0.1,0c-1.1,0.7-2,1.4-4.5,2
		c-1.4,0.3-3.5,2.5-3.9,3.4c-0.2,0.4-0.5,1.1-0.7,1.9c-0.1,0.7-0.1,1.4-0.2,2c0,0.6,0,1.2,0,1.5l0,0.2c0,0.1,0.1,1.9-0.1,2.8
		c-0.2,1.4-1.5,2-2,2.1C69.6,80.9,69.4,80.9,69.2,80.9z"/>
</g>
</svg>

  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.thelogo__svg {
  width: 100%;
  height: 100%;
}
.thelogo__color {
  fill: white;
}

.thelogo {
  &.isblack {
    .thelogo__color {
      fill: black;
    }
  }
}
</style>
